import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/1Inch/Banner"
import Whatis from "../components/1Inch/Whatis"
//import QuickviewSection from "../components/1Inch/Quickview"
import HowOur from "../components/1Inch/HowOur"
import TopFeatures from "../components/1Inch/TopFeatures"
import CoreFeatures from "../components/1Inch/CoreFeatures"
import SecurityofOur from "../components/1Inch/SecurityofOur"
import TechStack from "../components/1Inch/TechStack"
import FaqSection from "../components/1Inch/FaqSection"
import WhyShould from "../components/1Inch/WhyShould"
import DevApproach from "../components/1Inch/DevApproach"
import WhyChoose from "../components/1Inch/WhyChoose"
import Addon from "../components/1Inch/Addon"
import Testimonial from "../components/1Inch/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"





const Clone = () => (
  <section className="landing clone">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>1inch Exchange Clone Script | Create DEX Aggregator like 1inch Exchange</title>
        <meta name="description" content="1inch Exchange Clone Script is a ready-made DEX aggregator script built on the Ethereum network with all the existing features of iinch exchange. Get a free demo now!" />
        <meta property="keywords" content="1inch Exchange Clone Software, White Label 1inch Exchange Clone Script, DEX Aggregator Script, DeFi Exchange Clone Script" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="1inch Exchange Clone Script | Create DEX Aggregator like 1inch Exchange" />
        <meta property="og:description" content="1inch Exchange Clone Script is a ready-made DEX aggregator script built on the Ethereum network with all the existing features of iinch exchange. Get a free demo now!" />
        <meta property="og:url" content="https://www.coinsclone.com/1inch-exchange-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/1inch-exchange-clone-script.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="1inch Exchange Clone Script is a ready-made DEX aggregator script built on the Ethereum network with all the existing features of iinch exchange. Get a free demo now!" />
        <meta name="twitter:title" content="1inch Exchange Clone Script | Create DEX Aggregator like 1inch Exchange" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/1inch-exchange-clone-script.png" />
        <link rel="canonical" href="https://www.coinsclone.com/1inch-exchange-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | 1inch Exchange Clone Script </span>
        </div>
      </div>
      <Whatis />
      {/* <QuickviewSection /> */}
      <HowOur />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Clone
