import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
                Coinsclone For 1inch Exchange Clone Script?</h3>
                <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img  width="491px" height="455px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/why-choose-1-inch.webp" alt="Why Choose our 1inch Exchange Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is a professional and reputed DeFi exchange clone script provider in the global crypto marketplace. Being a leading clone script service provider, we have great experience in developing and delivering high-quality script services with impeccable outcomes. Our 1inch Exchange clone script offers highly improved liquidity as it was built under the Ethereum blockchain and its optimized algorithm allows your users to swap ERC20 tokens securely.  
              </p>
              <p className="pharagraph mb-0">Our premium 1inch clone script maintains an accurate transaction ledger in real-time that can be accessed 24 x 7. Our clone script seamlessly integrates with your existing UI/UX and even a brand interface. The script we provide makes a way for more liquidity which eliminates an atmosphere of volatility that plagues the other DEX platforms. We have a dedicated team of blockchain experts to provide implementation support and for making customizations in the script. Besides, we have qualified designers, skilled blockchain developers, mobile app developers, and quality analysts to shape the script as per the client's input. This statement clearly shows our product outcome will be a phenomenal one.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose