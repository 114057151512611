import React from 'react'




const HowOur = () => {



  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          
          <h2 className="heading-h2"><span className="heading-h3 bluecolor">Working Process</span>
          Of Our 1inch Clone Script Work</h2>
        </div>
        <img width="1123px" height="1538px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/1inch-workflow.webp" alt="Working flow of our 1inch clone software" />
      </div>
    </section>
  )
}

export default HowOur